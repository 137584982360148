import config from "../../config"
import { authHeader } from "../helpers/auth_header"

export default class lifeService {
  getLines() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifelines`, requestOptions).then(
      handleResponse
    )
  }

  getTjalie() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifelines/tjalie`, requestOptions).then(
      handleResponse
    )
  }

  getPiekir(showOnlyEnabled) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    var p = showOnlyEnabled ? "piekir_public" : "piekir"

    return fetch(`${config.iwib.server}/lifelines/${p}`, requestOptions).then(
      handleResponse
    )
  }

  deleteLine(lineId) {
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifelines/${lineId}`, requestOptions).then(
      handleResponse
    )
  }

  getLine(lineId) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifelines/${lineId}`, requestOptions).then(
      handleResponse
    )
  }

  getPoints(lineId) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(
      `${config.iwib.server}/lifepoints/line/${lineId}`,
      requestOptions
    ).then(handleResponse)
  }

  getHistoricalPoints() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(
      `${config.iwib.server}/lifepoints/historicalline`,
      requestOptions
    ).then(handleResponse)
  }


  getPoint(pointId) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifepoints/${pointId}`, requestOptions).then(
      handleResponse
    )
  }

  updatePoint(point) {
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(point)
    }
    return fetch(`${config.iwib.server}/lifepoints/public/${point.id}`, requestOptions).then(
      handleResponse
    )
  }


  deletePublicPoint(pointId) {
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    }

    return fetch(`${config.iwib.server}/lifepoints/public/${pointId}`, requestOptions).then(
      handleResponse
    )
  }

  createLineWithPoints(line) {
    const requestOptions = {
      method: "POST",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(line)
    }

    return fetch(`${config.iwib.server}/lifelines/public`, requestOptions).then(
      handleResponse
    )
  }

  updateLineWithPoints(line) {
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(line)
    }

    return fetch(`${config.iwib.server}/lifelines/public`, requestOptions).then(
      handleResponse
    )
  }

}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
